<template>
  <div class="submenu">
    <div class="submenu-list">
      <SubMenuItemCard
        v-for="item in items"
        :key="item.label"
        :name="item.label"
        @click="onClick(item)"
        :badge="loadBadge(item)"
      />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import store from "../../store";
import SubMenuItemCard from "../../widgets/menu/SubMenuItemCard.vue";
export default {
  components: { SubMenuItemCard },
  props: {
    items: {
      type: Array,
      default: [],
    },
  },
  setup() {
    const router = useRouter();
    const onClick = (item) => router.push(item.to);

    const loadBadge = (data) => {
      switch (data.notificationTypeId) {
        case 1:
          return store.state.auth.buildingBadge;
        case 2:
          return store.state.auth.paymentBadge;
        case 6:
          return store.state.auth.assemblyBadge;
        case 3:
          return store.state.auth.messageBadge;
        case 8:
          return store.state.auth.licenseBadge;
        default:
          break;
      }
    };

    return { onClick, loadBadge };
  },
};
</script>

<style scoped>
.submenu {
  display: flex;
  justify-content: center;
}
.submenu-list {
  display: flex;
  flex-direction: column;
}
</style>
