<template>
  <div class="container">
    <p class="text">{{name}}</p>
    <div class="badge-container">
      <p v-if="badge" class="badge">{{badge}}</p>
      <i class="pi pi-angle-right icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'Menu Item'
    },
    badge: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.container {
  width: 350px;
  height: 60px;
  border-radius: .5rem;
  background-color: white;
  box-shadow: 0 0 20px rgba(35, 141, 130, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.5rem;
}
.text {
  color: var(--primary-dark);
  margin-left: 1rem;
}
.icon {
  font-size: 1.2rem;
  margin-right: 1rem;
  color: var(--primary);
}
.badge-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge {
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: .75rem;
  font-size: .6rem;
  font-family: 'klavika';
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--danger);
  color: #fff;
}
</style>