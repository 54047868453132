<template>
  <div class="title">
    <h2>{{ title }}</h2>
    <div v-if="separator" class="title-separator"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    separator: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 2rem;
  text-align: center;
}
.title > h2 {
  font-size: 1.2rem;
  color: var(--primary-dark);
}
.title-separator {
  border-top: 2px solid var(--primary-dark);
  width: 4rem;
  margin: 1rem auto;
}
</style>
